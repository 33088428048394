<template>
  <div class="customer">
    <div class="content">
      <div class="chat flex">
        <div class="chatLeft"></div>
        <div class="chatContent">
          <div class="info flex2">
            <div class="name">{{ mainInfo.student_name }}</div>
            <div class="sex">{{ mainInfo.sex }} {{ mainInfo.age }}岁</div>
          </div>
          <div class="describe">症状描述：{{ mainInfo.content }}</div>
          <span
            class="upload"
            @click="showPopup(fileList)"
            v-for="(item, index) in fileList"
            :key="index"
          >
            <img :src="item.url" alt="" class="fileListimg" />
          </span>
        </div>
        <div class="chatRight">
          <img
            src="https://img01.yzcdn.cn/vant/cat.jpeg"
            class="userAvatar"
            alt=""
          />
        </div>
        <van-popup
          v-model="fileShow"
          :style="{ height: 'auto', width: '100%' }"
        >
          <div>
            <img :src="showImg" style="width:100%;height:100%" alt="" />
          </div>
        </van-popup>
      </div>
      <!-- 留言回复遍历 -->
      <div class="chat flex" v-for="item in mainInfo.children" :key="item.id">
        <div class="chatLeft">
          <img
            src="https://img01.yzcdn.cn/vant/cat.jpeg"
            class="userAvatar"
            alt=""
          />
        </div>
        <div class="chatContent cat1">
          <div class="describe1">
            {{ item.content }}
          </div>
          <span class="upload" @click="showPopup(item.img)" v-for="(item1, index1) in item.img" :key="index1"><img :src="item1.url" alt="" class="fileListimg" /> </span>
        </div>
        <div class="chatRight"></div>
      </div>
      <!-- info部分 -->
      <div v-for="itemone in doctorInfo" :key="itemone.id">
        <!-- 用户 -->
        <div class="chat flex">
          <div class="chatLeft"></div>
          <div class="chatContent">
            <div class="describe1">{{ itemone.content }}</div>
          </div>
          <div class="chatRight">
            <img
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
              class="userAvatar"
              alt=""
            />
          </div>
        </div>
        <!-- 客服 -->
        <div class="chat flex" v-for="item in itemone.children" :key="item.id">
          <div class="chatLeft">
            <img
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
              class="userAvatar"
              alt=""
            />
          </div>
          <div class="chatContent cat1">
            <div class="describe1">
              {{ item.content }}
            </div>
            <span class="upload" @click="showPopup(item.img)" v-for="(item1, index1) in item.img" :key="index1"><img :src="item1.url" alt="" class="fileListimg" /> </span>
          </div>
          <div class="chatRight"></div>
        </div>
      </div>
      <div class="chat flex" v-for="(item, index) in contentList" :key="index">
        <div class="chatLeft"></div>
        <div class="chatContent">
          <div class="describe">
            {{ item }}
          </div>
        </div>
        <div class="chatRight">
          <img
            src="https://img01.yzcdn.cn/vant/cat.jpeg"
            class="userAvatar"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="send">
      <div class="search">
        <van-search
          v-model="wordValue"
          show-action
          shape="round"
          :clearable="false"
          placeholder="请留言"
          input-align="left"
          left-icon=""
        >
          <template #action>
            <div @click="vaeSearch" class="seachStyle">发送</div>
          </template>
        </van-search>
      </div>
    </div>
  </div>
</template>

<script>
import { consultationDetail, consultationReply } from '@/api/studentProduct'
import messageComponent from './components/messageComponent.vue'
import { ImagePreview } from 'vant';
export default {
  components: {
    messageComponent,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data () {
    return {
      wordValue: '',
      contentList: [],
      mainInfo: {},
      doctorInfo: [],
      consultation_id: this.$route.query.id,
      fileList: [],
      fileShow: false,
      showImg: ''
    }
  },
  created () {
    const id = this.$route.query.id
    this.getInfo(id)
  },
  methods: {
    async vaeSearch (val) {
      if (this.wordValue == '') {
        return
      } else {
        const res = await consultationReply({
          consultation_id: parseInt(this.consultation_id),
          content: this.wordValue,
          img: ''
        })
        if (res.data.code == 200) {
          this.contentList.push(this.wordValue)
          this.wordValue = ''
        } else {
          return
        }
      }
    },
    async getInfo (id) {
      const res = await consultationDetail({ consultation_id: id })
      if (res.data.code == 200) {
        this.mainInfo = res.data.data.main
        this.mainInfo.img.forEach(element => {
          this.fileList.push(element)
        })
        console.log(this.fileList)
        this.doctorInfo = res.data.data.info
      }
    },
    showPopup (val) {
        const arr = []
      val.forEach(item=>{
        arr.push(item.url)
      })
      ImagePreview(arr)
    }
  }
}
</script>

<style lang="less" scoped>
.customer {
  width: 100%;
  position: relative;
  .content {
    padding: 30px 15px;
    .chat {
      margin-bottom: 40px;
      .chatLeft {
        text-align: center;
        flex: 1;
      }
      .chatContent {
        flex: 3;
        padding: 10px;
        background-color: #fff;
        border-radius: 15px 0px 15px 15px;
        box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.16);
        .upload {
          font-size: 60px;
          .fileListimg {
            width: 60px;
            height: 60px;
            margin-right: 5px;
          }
        }

        .info {
          color: #333333;
          font-size: 12px;
          .name {
            font-size: 16px;
            font-weight: 600;
          }
          .sex {
            padding-left: 15px;
            color: #666666;
          }
        }
        .describe {
          margin-top: 6px;
          line-height: 22px;
          font-size: 14px;
          color: #666666;
        }
        .describe1 {
          line-height: 22px;
          font-size: 14px;
          color: #666666;
        }
      }
      .cat1 {
        border-radius: 0 15px 15px 15px;
      }
      .chatRight {
        text-align: center;
        flex: 1;
      }
      .userAvatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #ccc;
      }
    }
  }
  .send {
    background: #f8f9f9;
    width: 99%;
    position: fixed;
    bottom: 0;
    z-index: 999;
  }
  .flex {
    display: flex;
    align-items: flex-start;
  }
  .flex1 {
    display: flex;
    justify-content: space-between;
  }
  .flex2 {
    display: flex;
    align-items: center;
  }
  .search {
    ::v-deep .seachStyle {
      font-size: 13px;
      padding: 0 10px;
      border-radius: 18px;
      color: #fff !important;
      background-color: #2163e9 !important;
    }
    ::v-deep .van-search {
      background: none;
    }
    ::v-deep .van-search__content--round {
      background-color: #fff;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    }
  }
  .textRight {
    text-align: right;
  }
  ::v-deep .van-uploader__preview-delete {
    display: none;
  }
}
</style>
