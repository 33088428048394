<!-- 医生留言 -->
<template>
  <div class="messageComponent">
    <div v-for="item in doctorInfo" :key="item.id">
      <div class="chat flex">
        <div class="chatLeft">
          <img
            src="https://img01.yzcdn.cn/vant/cat.jpeg"
            class="userAvatar"
            alt=""
          />
        </div>
        <div class="chatContent cat1">
          <div class="describe1">
            {{ item.content }}
          </div>
        </div>
        <div class="chatRight"></div>
      </div>
      <!-- 子级 -->
      <div class="chat flex" v-for="itemone in item.children" :key="itemone.id">
        <div class="chatLeft">
          <img
            src="https://img01.yzcdn.cn/vant/cat.jpeg"
            class="userAvatar"
            alt=""
          />
        </div>
        <div class="chatContent cat1">
          <div class="describe1">
            {{ itemone.content }}
          </div>
        </div>
        <div class="chatRight"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    doctorInfo: {
      type: Array
    }
  }
}
</script>

<style lang="less" scoped>
.messageComponent {
  .chat {
    margin-bottom: 40px;
    .chatLeft {
      text-align: center;
      flex: 1;
    }
    .chatContent {
      flex: 3;
      padding: 10px;
      background-color: #fff;
      border-radius: 15px 0px 15px 15px;
      box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.16);
      .info {
        color: #333333;
        font-size: 12px;
        .name {
          font-size: 16px;
          font-weight: 600;
        }
        .sex {
          padding-left: 15px;
          color: #666666;
        }
      }
      .describe {
        margin-top: 6px;
        line-height: 22px;
        font-size: 14px;
        color: #666666;
      }
      .describe1 {
        line-height: 22px;
        font-size: 14px;
        color: #666666;
      }
    }
    .cat1 {
      border-radius: 0 15px 15px 15px;
    }
    .chatRight {
      text-align: center;
      flex: 1;
    }
    .userAvatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
    .flex {
      display: flex;
      align-items: flex-start;
    }
    .flex1 {
      display: flex;
      justify-content: space-between;
    }
    .flex2 {
      display: flex;
      align-items: center;
    }
  }
}
</style>
